import React from 'react';
import { SingleDatePicker as DatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './custom_datepicker.sass';
import { Moment } from 'moment';

interface IProps {
  block?: boolean;
  calendarInfoPosition?: 'top' | 'bottom';
  date?: Moment;
  disabled?: boolean;
  disableScroll?: boolean;
  focused?: boolean;
  id?: string;
  isOutsideRange?: ( day: Moment ) => boolean;
  noBorder?: boolean;
  numberOfMonths?: number;
  placeholder?: string;
  renderCalendarInfo?: () => React.ReactNode;
  renderDayContents?: ( value: Moment, mods: Set<string> ) => React.ReactNode;
  required?: boolean;
  showDefaultInputIcon?: boolean;
  small?: boolean;
  withPortal?: boolean;
  onDateChange?: ( date: Moment ) => void;
  onFocusChange?: ({ focused }: { focused: boolean }) => void;
}

export const SingleDatePicker: React.FC<IProps> = ({
  block,
  calendarInfoPosition,
  date,
  disabled,
  disableScroll,
  focused,
  id,
  isOutsideRange = () => false,
  numberOfMonths,
  placeholder,
  renderCalendarInfo,
  renderDayContents,
  required,
  showDefaultInputIcon,
  small,
  withPortal,
  onDateChange = () => {},
  onFocusChange = () => {},
}) => (
  <DatePicker
    block={block}
    calendarInfoPosition={calendarInfoPosition}
    date={date}
    disabled={disabled}
    disableScroll={disableScroll}
    focused={focused}
    hideKeyboardShortcutsPanel
    id={id}
    isOutsideRange={isOutsideRange}
    noBorder
    numberOfMonths={numberOfMonths}
    placeholder={placeholder}
    renderCalendarInfo={renderCalendarInfo}
    renderDayContents={renderDayContents}
    required={required}
    showDefaultInputIcon={showDefaultInputIcon}
    small={small}
    withPortal={withPortal}
    onDateChange={onDateChange}
    onFocusChange={onFocusChange}
  />
);
